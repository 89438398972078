import YouTubePlayer from 'youtube-player'

export default class Youlist {
    constructor(el, autoplay=true) {

        // If it's a string get dom node
        if(typeof el == 'string')
            el = document.querySelector(el)

        // Check if el is a div
        if(!el || el.tagName != 'DIV') {
            console.warn('Element not found or invalid. Use a div bro.')
            return false
        }

        // Define dom element
        this.el = el

        // Check dom
        if(!this.el.querySelector('.youlist__player') || !this.el.querySelector('.youlist__list') || !this.el.querySelectorAll('.youlist__video')) {
            console.warn('Invalid structure. Be sure to have a player and some videos in a list bro.')
            return false
        }

        // Get dom
        this.player = this.el.querySelector('.youlist__player')
        this.list = this.el.querySelector('.youlist__list')
        this.videos = this.el.querySelectorAll('.youlist__video')

        // Create vars
        this.currentVideo = false

        // Generate Dom
        this.generatePlayer()
        
        // Play first video
        if(autoplay)
            this.play(this.videos[0].dataset.id)

        // Listen videos click
        this.videos.forEach(video => {
            video.addEventListener('click', e => {
                e.preventDefault()
                this.play(video.dataset.id)
            })
        })

        // Listen player end
        this.player.on('stateChange', event => {
            switch (event.data) {
                case 0:
                    this.playNext()
                    break
                default:
                    break
            }
        })
    }

    play(id) {
        this.currentVideo = id
        this.player.loadVideoById(id)
        // Remove current class and add new
        if(this.list.querySelector('.youlist__video--current'))
            this.list.querySelector('.youlist__video--current').classList.remove('youlist__video--current')
        this.list.querySelector(`[data-id="${id}"]`).classList.add('youlist__video--current')
    }

    playNext() {
        // Get next element and get first if we reached end
        let nextElement = this.list.querySelector('.youlist__video--current').nextElementSibling
        if(!nextElement)
            nextElement = this.list.querySelector('.youlist__video:first-child')
        this.play(nextElement.dataset.id)
    }

    pause() {
        this.player.stopVideo()
    }

    generatePlayer() {
        this.player = YouTubePlayer(this.player, {
            playerVars: {
                modestbranding: 1,
                playsinline: 1
            }
        })
    }
}