import '@scss/main.scss'

import 'picturefill'
import 'lazysizes'

import SmoothScroll from 'smooth-scroll'
import Swiper from 'swiper'
import Youlist from './libs/Youlist'
import Accordino from './libs/Accordino'

document.addEventListener('DOMContentLoaded', function() {

    // Burger menu
    document.querySelector('.a-burger').addEventListener('click', e => {
        e.preventDefault()
        if(!document.querySelector('.o-body').classList.contains('o-body--menu-on')) {
            document.querySelector('.o-body').classList.add('o-body--menu-on')
        } else {
            document.querySelector('.o-body').classList.remove('o-body--menu-on')
        }
    })
    document.querySelector('.m-menu__background').addEventListener('click', () => {
        document.querySelector('.o-body').classList.remove('o-body--menu-on')
    })

    //Popular resources
    const resourcesImages = document.querySelector('.m-slider-resources__images')
    if(resourcesImages) {
        const imagesSlider = new Swiper(resourcesImages, {
            speed: 600,
            grabCursor: true,
            pagination: {
                el: '.swiper-pagination',
                clickable: true
            }
        })       
        const contentSlider = new Swiper('.m-slider-resources__content', {
            speed: 600,
            // effect: 'fade',
            // fadeEffect: {
            //     crossFade: true
            // },
            spaceBetween: 40,
            autoHeight: true,
            navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            },
            parallax: true,
            controller: {
                control: imagesSlider,
            }
        })
        imagesSlider.controller.control = contentSlider
    }

    // Demos playlist
    const youlist = document.querySelector('.youlist')
    if(youlist) {
        // Create playlist
        const demos = new Youlist(youlist, false)
        // If we have a id query var
        if(getQueryVariable('id')) {
            demos.play(getQueryVariable('id'))
        } else {
            demos.play(demos.videos[0].dataset.id)
        }
        // Init smoothscroll
        const smooth = new SmoothScroll()
        demos.player.on('stateChange', event => {
            if(event.data == 1) {
                // Scroll to video
                if(window.innerWidth < 1024) {
                    smooth.animateScroll(demos.el)
                }
                // Remove last and show new detail
                if(document.querySelector('.m-demos__detail--active'))
                    document.querySelector('.m-demos__detail--active').classList.remove('m-demos__detail--active')
                document.querySelector(`.m-demos__detail[data-id="${demos.currentVideo}"]`).classList.add('m-demos__detail--active')
            }
        })
    }

    // Accordino on filters
    const accordinos = document.querySelectorAll('.accordino')
    if(accordinos) {
        for (let i = 0; i < accordinos.length; i++) {
            const accordino = new Accordino(accordinos[i])
            if(window.innerWidth > 1023) {
                accordino.open()
            }
        }
    }

    // Resources infinite scroll
    const resources = document.querySelector('.m-resources')
    if(resources) {
        let page = 1
        let request = false
        const list = document.querySelector('.m-view-list_ressources')
        const pagination = 12

        // Listen filters
        const filters = document.querySelectorAll('.m-resources__filter-content')
        for (let i = 0; i < filters.length; i++) {
            filters[i].addEventListener('click', e => {
                if(e.target.classList.contains('m-resources__choice')) {
                    // Reset page
                    page = 0
                    // Add loading class
                    resources.classList.add('m-resources--loading')
                    // Check if we display all
                    // Add new active class if its not "all" or ever active
                    if(e.target.dataset.id != 'all' && !e.target.classList.contains('m-resources__choice--active')) {
                        // // Remove old active class
                        // if(filters[i].querySelector('.m-resources__choice--active'))
                        //     filters[i].querySelector('.m-resources__choice--active').classList.remove('m-resources__choice--active')
                        // Add el active class
                        e.target.classList.add('m-resources__choice--active')
                    } else if(e.target.classList.contains('m-resources__choice--active')) {
                        // Remove el active class
                        e.target.classList.remove('m-resources__choice--active')
                    } else {
                        // Remove all els active class
                        if(filters[i].querySelector('.m-resources__choice--active')) {
                            const activeFilters = filters[i].querySelectorAll('.m-resources__choice--active')
                            activeFilters.forEach(filter => {
                                filter.classList.remove('m-resources__choice--active')
                            })
                        }
                    }
                    // Load resources
                    loadResources()
                }
            })
        }
        // Listen more page click
        const more = resources.querySelector('.m-resources__more')
        // const total = more.dataset.total
        more.addEventListener('click', e => {
            e.preventDefault()
            // Add loading class
            resources.classList.add('m-resources--loading')
            // Load resources
            loadResources()
        })
        // If we havent enough resources on init, hide mort button
        if(document.querySelectorAll('.m-view-list_ressources__row').length == pagination) {
            more.classList.remove('a-btn--hidden')
        }
        // Initial check for url args
        getURLArgs(true)
        // Popstate handle
        window.addEventListener('popstate', function (event) {
            getURLArgs()
        }, false);
        function getURLArgs(initial = false) {
            // Get args
            const types = getQueryVariables('type[]')
            const categories = getQueryVariables('category[]')
            if(types.length || categories.length) {
                if(types.length) {
                    types.forEach(type => {
                        // Add class on URL args
                        document.querySelector(`.m-resources__choice[data-filter="type"][data-id="${type}"]`).classList.add('m-resources__choice--active')
                    })
                }
                if(categories.length) {
                    categories.forEach(category => {
                        // Add class on URL args
                        document.querySelector(`.m-resources__choice[data-filter="category"][data-id="${category}"]`).classList.add('m-resources__choice--active')
                    })
                }
                if(!initial) {
                    // Add loading class
                    resources.classList.add('m-resources--loading')
                    // Load resources
                    loadResources()
                }
            }
        }
        function getArgs() {
            // Get args
            const activeChoices = document.querySelectorAll('.m-resources__choice--active')
            let args = ''
            for (let i = 0; i < activeChoices.length; i++) {
                args += `&${activeChoices[i].dataset.filter}[]=${activeChoices[i].dataset.id}`
            }
            return args
        }
        function loadResources() {
            // Get active args
            const args = getArgs()
            // Ajax request
            request = new XMLHttpRequest()
            request.onreadystatechange = () => {
                displayResources(args)
            }
            request.open('GET', `/ressources?page=${page}${args}`)
            request.send()
        }
        function displayResources(args) {
            if (request.readyState === request.DONE) {
                if (request.status === 200) {      
                    // Request success
                    const div = document.createElement('div')
                    div.innerHTML = request.responseText
                    // Remove resources if page 0
                    if(page == 0)
                        removeResources()
                    // Append new resources
                    let count = 0
                    for (let i = 0; i < div.childNodes.length; i++) {
                        if(div.childNodes[i].tagName == 'DIV') {
                            list.append(div.childNodes[i])
                            count ++
                        }
                    }
                    // Remove loading class
                    resources.classList.remove('m-resources--loading')
                    // If we still got content
                    if(count >= pagination) {
                        // Increment page count
                        page++
                        // Show more button
                        more.classList.remove('a-btn--hidden')
                    } else {
                        // Hide more button
                        more.classList.add('a-btn--hidden')
                    }
                    // Push new URL
                    let cleanArgs = ''
                    if(args != '') {
                        cleanArgs = `?${args.substring(1)}`
                    }
                    const newURL = window.location.pathname + cleanArgs
                    window.history.replaceState('', document.title, newURL) 
                } else {
                    // Remove loading class
                    more.classList.remove('a-btn--hidden')
                    // Display error message
                    alert('Un problème est survenu lors du chargement des ressources.');
                }
            }
        }
        function removeResources() {
            list.innerHTML = ''
        }
    }

    // Hide contentinfo on click
    const contentinfo = document.querySelector('.contentinfo')
    if(contentinfo) {
        contentinfo.addEventListener('click', () => {
            contentinfo.classList.add('contentinfo--hidden')
        })
    }

})

function getQueryVariable(variable) {
    const query = window.location.search.substring(1)
    const vars = query.split("&")
    for (let i=0; i<vars.length; i++) {
        const pair = vars[i].split("=")
        if(pair[0] == variable)
            return pair[1]
    }
    return false
}
// Function to get args when multiple with same name
function getQueryVariables(variable) {
    const query = window.location.search.substring(1)
    const vars = query.split("&")
    const values = []
    for (let i=0; i<vars.length; i++) {
        const pair = vars[i].split("=")
        if(pair[0] == variable)
            values.push(pair[1])
    }
    if(values) {
        return values
    } else {
        return false
    }
}

// SWPL LOG
console.log('%c S W P L ', 'background: #1467ff; color: white; font-size: 17px; font-weight: bold; line-height: 36px; text-align: center', ' www.swpl.fr');

// Source: https://github.com/jserz/js_piece/blob/master/DOM/ParentNode/append()/append().md
(function (arr) {
    arr.forEach(function (item) {
        if (item.hasOwnProperty('append')) {
            return;
        }
        Object.defineProperty(item, 'append', {
            configurable: true,
            enumerable: true,
            writable: true,
            value: function append() {
                var argArr = Array.prototype.slice.call(arguments),
                    docFrag = document.createDocumentFragment();
        
                argArr.forEach(function (argItem) {
                    var isNode = argItem instanceof Node;
                    docFrag.appendChild(isNode ? argItem : document.createTextNode(String(argItem)));
                });
    
                this.appendChild(docFrag);
            }
        });
    });
})([Element.prototype, Document.prototype, DocumentFragment.prototype]);

// Polyfill foreach
if (window.NodeList && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = function (callback, thisArg) {
        thisArg = thisArg || window;
        for (var i = 0; i < this.length; i++) {
            callback.call(thisArg, this[i], i, this);
        }
    };
}